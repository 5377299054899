import React from 'react';
import AkhilImage from './akhil.jpg';
import GabrielImage from './gabriel.jpg'; // Replace with actual path
import MarkandayImage from './markanday.jpg'; // Replace with actual path

function Team() {
  const team = [
    {
      name: "Akhil Yeleswarapu",
      role: "Co-Founder & Visionary",
      image: AkhilImage,
      description: "Akhil Yeleswarapu is a visionary leader with a unique blend of technical expertise and spiritual insight. As the founder of Nexclap and co-founder of Siliconvalley4u, he has taught and inspired young minds in the field of technology, building platforms that reflect his deep connection to education. His travels across India have enriched his understanding of ancient wisdom traditions, which he complements with his daily practice of meditation, pranayama, and chanting. Akhil has also studied Sanskrit and spent time in various ashrams, where he has immersed himself in spiritual practices. His work is a fusion of technology and spirituality, aimed at fostering a community-centered approach to self-development."
    },
    {
      name: "Gabriel Begin",
      role: "Co-Founder & Vedic Astrologer",
      image: GabrielImage, // Replace with actual import path for Gabriel's image
      description: "Gabriel Begin is a highly skilled Vedic astrologer with a profound understanding of ancient astrological principles. He offers personalized readings that integrate both traditional Vedic techniques and modern insights, aiming to provide clients with deep clarity and guidance on their life paths. Gabriel's approach is holistic, focusing on empowering individuals to align with their true purpose and navigate life's challenges with wisdom. His readings are known for their depth, accuracy, and the compassionate guidance he offers, making astrology a tool for personal transformation and self-awareness. Gabriel's work reflects a dedication to helping others live more authentically, rooted in the timeless wisdom of Vedic astrology."
    },
    {
      name: "Markanday Ravi",
      role: "Co-Founder & Spiritual Organizer",
      image: MarkandayImage, // Replace with actual import path for Markanday's image
      description: "Markanday Ravi is deeply committed to spiritual growth through service, meditation, prayer, and knowledge. His dedication to a life of self-development deepened after overcoming significant health challenges in early adulthood. He is actively involved with the Chinmaya Mission and has also engaged with the Vedanta Society, Art of Living, and Sivananda Yoga Vedanta Centre. Markanday’s diverse skill set includes event planning, writing, logistics, discussion facilitation, and project management. In his free time, he enjoys yoga, reading, exercise, and pursuing sustainable living practices. His journey reflects a harmonious blend of traditional spiritual practices with modern lifestyle choices."
    }
  ];

  return (
    <div class="scroll-container">
    <div className="container my-5">
      <h1 className="text-center mb-5">Our Team</h1>
      <div className="row">
        {team.map((member, index) => (
          <div className="col-md-4" key={index}>
            <div className="card mb-4">
              <img
                src={member.image}
                className="card-img-top"
                alt={member.name}
              />
              <div className="card-body">
                <h5 className="card-title">{member.name}</h5>
                <h6 className="card-subtitle mb-2 text-muted">{member.role}</h6>
                <p className="card-text">{member.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
}

export default Team;
