import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import Team from './Team';

function App() {
  const [data, setData] = useState([{}]);

  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/team" element={<Team />} />
        </Routes>
      </div>
    </Router>
  );
}

function Home() {
  const navigate = useNavigate();

  const navigateToTeam = () => {
    navigate('/team');
  };

  const handleEventDetailsClick = () => {
    window.location.href = 'https://www.eventcreate.com/e/designapath';
  };
  
  return (
    <div>
      <div id="stars"></div>
      <div id="stars2"></div>
      <div id="stars3"></div>
      <div id="title">
        <span className="header">Design a Path</span>
        <span className="title-small">Commununity Support and Activities</span>
        <div className="description-box">
          <p className='paragraph'>
            Inspired by the South Asian wisdom traditions, Design a Path’s vision is to promote community and connection through organizing uplifting volunteer, spiritual, and social events. Our organization intends to create a compassionate community that supports people, helps them foster a deep and meaningful life, and is not aligned with any particular religion or philosophy.
          </p>
        </div>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSeB4AKZXB4P3SozR58T7oVCSPqXWtcnA2pfciFOwNSGPoxp_A/viewform?usp=sf_link" target="_blank" className="mailing-list-button">
         Mailing List!
        </a>
        <button onClick={navigateToTeam} id="register">Our Team</button>
        <button onClick={handleEventDetailsClick} id="next-event-details">Next Event Details!</button>
        <div className="divine-symbols">
      <div className="symbol">ॐ</div> {/* Om */}
      <div className="symbol">स्वस्तिक</div> {/* Swastika */}
      <div className="symbol">श्री</div> {/* Shri */}
      <div className="symbol">त्रिशूल</div> {/* Trishula */}
      <div className="symbol">कमल</div> {/* Lotus */}
    </div>
      </div>
    </div>
  );
}

export default App;
